<template>
  <div class="grouplist-wrap">
    <div class="bread-wrap">
      <span class="bread-title">本账号客户资源汇总</span>
      <download-excel
        class="export-excel-wrapper"
        v-if="tabledata && tabledata.length != 0"
        :data="tabledata"
        :fields="json_fields"
        name="本账号客户资源汇总.xls"
      >
        <el-button type="text" v-if="tabledata && tabledata.length != 0"
          >导出excel表格</el-button
        >
      </download-excel>
    </div>

    <div class="content">
      <com-table
        class="grouplist"
        :headercellstyle="headercellstyle"
        :columns="channelResrouce"
        :pagination="pagination"
        tableheight="calc(100vh - 230px)"
        :data="tabledata"
        :rowFilter="rowFilter"
        searchHint="支持手机号，客户ID，资源ID，产品，资源状态模糊搜索"
      >
      </com-table>
    </div>
    <edite-name
      title="关联ID"
      :row="row"
      :showeditename="showeditename"
      @closeeditename="closeeditename"
    />
  </div>
</template>
<script>
import ComTable from "@/components/Table";
import { channelResrouce } from "@/utils/tableList";
import EditeName from "./Models/EditeName";
import formatdate from "@/utils/format";
import { IpRegionMap, ProductTypeMap, LineStatusMap } from "@/utils/constants";

export default {
  name: "channelResrouce",
  data() {
    return {
      channelResrouce,
      json_fields: {
        无觅手机号: "PhoneNumber",
        无觅ID: "UserId",
        资源类型: "ResourceType",
        资源ID: "ResourceId",
        产品: {
          callback: (value) => {
            return ProductTypeMap[value.ProductId] || value.ProductId;
          },
        },
        地区: {
          callback: (value) => {
            return IpRegionMap[value.RegionId] || value.RegionId;
          },
        },
        IP: {
          callback: (value) => {
            return value.Ip || "-";
          },
        },
        折扣: {
          callback: (value) => {
            return value.Discount || "1.0";
          },
        },
        资源创建时间: {
          callback: (value) => {
            return formatdate(value.CreateTime, "YYYY-MM-DD");
          },
        },
        资源到期时间: {
          callback: (value) => {
            return formatdate(value.ExpireTime, "YYYY-MM-DD");
          },
        },
        资源状态: {
          callback: (value) => {
            return LineStatusMap[value.Status] || value.Status;
          },
        },
        关联ID: {
          callback: (value) => {
            return value.ChannelOuterId || "-";
          },
        },
      },
      pagination: {
        current: 1,
        size: 20,
        total: 1,
      },
      tabledata: [],
      headercellstyle: {
        background: "rgba(228, 229, 241)",
        color: "rgba(102,102,102)",
      },
      row: {},
      title: "",
      tempdata: [],
      showeditename: false,
    };
  },
  components: {
    ComTable,
    EditeName,
  },
  mounted() {
    this.getgrouplist();
    window.editChannelOuterId = this.editChannelOuterId;
    this.getuserinfo();
  },
  methods: {
    getgrouplist() {
      var tempdata = [];
      this.$store.dispatch("user/channelsublist").then((res) => {
        if (res.RetCode == 0) {
          res.List.length &&
            res.List.forEach((item, index) => {
              item.PathResources &&
                item.PathResources.forEach((eitem, eindex) => {
                  eitem.CompanyName = item.CompanyName;
                  eitem.PhoneNumber = item.PhoneNumber;
                  eitem.Discount = item.PathDiscount;
                  eitem.ResourceType = "直播快";
                  eitem.UserId = item.UserId;
                  eitem.ChannelOuterId = item.ChannelOuterId;
                  tempdata.push(eitem);
                });
              item.PhoneResources &&
                item.PhoneResources.forEach((eitem, eindex) => {
                  eitem.CompanyName = item.CompanyName;
                  eitem.PhoneNumber = item.PhoneNumber;
                  eitem.Discount = item.PhoneDiscount;
                  eitem.ResourceType = "云手机";
                  eitem.UserId = item.UserId;
                  eitem.ChannelOuterId = item.ChannelOuterId;
                  tempdata.push(eitem);
                });
            });
          this.tabledata = tempdata;
        }
      });
    },
    editChannelOuterId(row) {
      this.row = row;
      this.showeditename = !this.showeditename;
    },
    closeeditename() {
      this.showeditename = !this.showeditename;
      this.getgrouplist();
    },
    getuserinfo() {
      this.$store.dispatch("user/getuserinfo").then((res) => {
        if (res.RetCode == 0) {
        }
      });
    },
    rowFilter(row, searchKey = '') {
      searchKey = searchKey.toLowerCase()
      return [
        row.PhoneNumber, row.UserId, row.ResourceId,
        ProductTypeMap[row.ProductId] || row.ProductId, LineStatusMap[row.Status] || row.Status
      ].map(v => v.toLowerCase()).some(v => v.includes(searchKey))
    }
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>